// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../../styleguide/components/Link/A.res.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DashboardNavbarCss from "../../../../../styleguide/dashboard/components/navbar/DashboardNavbarCss.res.js";
import * as Routes_SuspiciousEmail from "../../../../../routes/common/Routes_SuspiciousEmail.res.js";

function DashboardSuspiciousEmailNavbar(props) {
  var suspiciousEmail = props.suspiciousEmail;
  var __currentLink = props.currentLink;
  var currentLink = __currentLink !== undefined ? __currentLink : "Index";
  var linkCss = function (link) {
    if (link === currentLink) {
      return DashboardNavbarCss.underlinedLinkContainer;
    } else {
      return DashboardNavbarCss.linkContainer;
    }
  };
  var tmp;
  tmp = currentLink === "Index" ? DashboardNavbarCss.underlinedLinkItem : DashboardNavbarCss.normalLinkItem;
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(A.make, {
                                  href: Routes_SuspiciousEmail.Dashboard.index,
                                  className: tmp,
                                  children: "Suspicious Emails"
                                }),
                            className: linkCss("Index")
                          }),
                      suspiciousEmail !== undefined ? JsxRuntime.jsx(JsxRuntime.Fragment, {
                              children: Caml_option.some(JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx(A.make, {
                                              href: Routes_SuspiciousEmail.Dashboard.edit(suspiciousEmail.id),
                                              className: DashboardNavbarCss.underlinedLinkItem,
                                              children: "Edit Suspicious Email"
                                            }),
                                        className: linkCss("Edit")
                                      }))
                            }) : null
                    ],
                    className: DashboardNavbarCss.barContainer
                  }),
              className: DashboardNavbarCss.barWrapper
            });
}

var Css;

var make = DashboardSuspiciousEmailNavbar;

export {
  Css ,
  make ,
}
/* A Not a pure module */
